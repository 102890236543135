import Vue from "vue";
import VueRouter from "vue-router";
import { userDataGet } from "@/components-js/requestSrv";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Start.vue"),
  },
  {
    path: "/today",
    name: "Today",
    component: () => import("@/views/Today.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/chesstable",
    name: "Chesstable",
    component: () => import("@/views/ChessTable.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/tomorrow",
    name: "Tomorrow",
    component: () => import("@/views/Tomorrow.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  {
    path: "/yesterday",
    name: "Yesterday",
    component: () => import("@/views/Yesterday.vue"),
    meta: { roles: ["user", "admin"], showMenu: true },
  },
  // {
  //   path: "/freeturns",
  //   name: "FreeTurns",
  //   component: () => import("@/views/FreeTurns.vue"),
  //   meta: { roles: ["user"], showMenu: true },
  // },
  {
    path: "/freeturns-admin",
    name: "FreeTurns",
    component: () => import("@/views/FreeTurns.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/personal",
    name: "PersonalTable",
    component: () => import("@/views/Personal.vue"),
    meta: { roles: ["user"], showMenu: true },
  },
  {
    path: "/personaladm",
    name: "PersonalTableAdm",
    component: () => import("@/views/Personal.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/salary",
    name: "Salary",
    component: () => import("@/views/Salary.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/salarybypandp",
    name: "Salarybypandp",
    component: () => import("@/views/SalaryByPandP.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/summary",
    name: "Summary",
    component: () => import("@/views/Summary.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/reports",
    name: "ReportList",
    component: () => import("@/views/Reports.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/peoplelist",
    name: "PeopleList",
    component: () => import("@/views/PeopleList.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/logs",
    name: "Logs",
    component: () => import("@/views/Logs.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },
  {
    path: "/turnsbytypes",
    name: "Turnsbytypes",
    component: () => import("@/views/Turnsbytypes.vue"),
    meta: { roles: ["admin"], showMenu: true },
  },

  // {
  //   path: "/wiki",
  //   name: "Wiki",
  //   beforeEnter() {
  //     window.open(
  //       "https://shemenev.notion.site/brew-wiki-35dd6fe2de9b4883ad14d0e7f2ac603d",
  //       "wiki"
  //     );
  //   },
  //   meta: { roles: ["user", "admin"], showMenu: true },
  // },
  // {
  //   path: "/freeturns",
  //   name: "FreeTurns",
  //   component: () => import("@/views/FreeTurns.vue"),
  //   meta: { roles: ["admin"], showMenu: true },
  // },
  // {
  //   path: "/auto",
  //   name: "Auto",
  //   component: () => import("@/views/Auto.vue"),
  //   meta: { roles: ["admin"], showMenu: true },
  // },
  {
    path: "/other",
    name: "Other",
    component: () => import("@/views/Other.vue"),
    meta: { roles: ["user"], showMenu: true },
  },
  {
    path: "/passchange",
    name: "Passchange",
    component: () => import("@/views/PassChange.vue"),
    meta: { roles: ["user"], showMenu: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Logout.vue"),
  },
  {
    path: "/*",
    name: "NotFound",
    meta: { needAuth: false, showMenu: true },
    component: { render: (h) => h("div", ["Page Not Found"]) },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // continue session, if token is still alive
  if (to.fullPath == "/") next({ name: "Today" });

  // if access check required
  if (to.meta.roles) {
    const user = await userDataGet();
    if (user.roles && user.roles.map((ur) => to.meta.roles.includes(ur)).includes(true))
      // access grant
      next();
    else {
      // if access denied, clear all
      // rolesClear();
      next({ name: "Login" });
    }
  }
  // welcome to public route
  next();
});

export default router;
